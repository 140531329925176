export type EnvironmentType = 'stable' | 'live';

export const checkEnvironment = (environment: EnvironmentType): boolean => {
  return process.env.DEVO_ENV === environment;
};

export const isLocalEnvironment = (): boolean => {
  return !process.env.NEXT_PUBLIC_DEVO_ENV;
};

export const isProductionEnvironment = (): boolean => {
  return checkEnvironment('live');
};

export const isStableEnvironment = (): boolean => {
  return checkEnvironment('stable');
};

export const isDebugEnvironment = (): boolean => {
  if (isProductionEnvironment()) return false;
  return isStableEnvironment() || isLocalEnvironment();
};

export const isLocalhostEnvironment = () => {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '' ||
    window.location.hostname === '127.0.0.1'
  );
};
